export const QUICK_REG = 'utm_quick_reg';
export const UNIK = 'utm_unik';
export const PREFER_BODY_TYPE = 'prefer_body_type';
export const PREFER_AGE = 'prefer_age';
export const REAL_AGE = 'utm_real_age';
export const LND_NAME = `lnd_name`;
export const NETW = 'utm_netw';
export const CLICKID = 'clickid';
export const TID = 'tid';
export const MSCLKID = 'msclkid';
export const PROFILE = 'utm_profile';
export const NAME_PROFILE = 'tatianaplove';
export const ID_PROFILE = '1279669230';

//PREFER BODY TYPE
export const BODY_TYPE_NOT_SPECIFIED = 'Not Specified';
export const BODY_TYPE_PETITE = 'Petite';
export const BODY_TYPE_SLIM = 'Slim';
export const BODY_TYPE_AVERAGE = 'Average';
export const BODY_TYPE_MUSCULAR_FIT = 'Muscular/Fit';
export const BODY_TYPE_CURVY = 'Curvy';

export const BODY_TYPE_NOT_SPECIFIED_ID = '0';
export const BODY_TYPE_PETITE_ID = '1';
export const BODY_TYPE_SLIM_ID = '2';
export const BODY_TYPE_AVERAGE_ID = '3';
export const BODY_TYPE_MUSCULAR_FIT_ID = '4';
export const BODY_TYPE_CURVY_ID = '5';