import Lnd20p4 from "../../../pages/lnd20p4";
import Lnd20Profile4 from "../../../pages/lnd20Profile4";

import * as ROUTES from '../../../constants/routes';

export function isTrafficLandingName() {
    return ROUTES.ROUTE_LIST_TRAFFIC.some(item => window?.location?.href?.includes(item)) ?? false;
}

export function getLandingName() {
    return ROUTES.ROUTE_LIST.find(item => window?.location?.href?.includes(item)) ?? ROUTES.LND20;
}

export function getParentLandingName() {
    return Object
        .keys(ROUTES.ROUTE_SOURCE_MAP)
        .find(sourceMapItem => ROUTES.ROUTE_SOURCE_MAP[sourceMapItem]?.find(routeItem => routeItem === getLandingName()));
}

export function getLandingPageComponent() {
    switch (getParentLandingName()) {
        case ROUTES.LND20P4:
            return Lnd20p4;
        case ROUTES.LND20_PROFILE4:
            return Lnd20Profile4;
        default:
            return Lnd20p4;
    }
}